@media only screen and (min-width: 1400px) {
  .trainee-cardcontainer {
    max-width: 1320px;
  }
}

.badge-text {
  color: #111;
}

.runbuttontraining {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
}

.trainee-card {
  border: 1px solid #ebebeb;
}

.btn-qatrainee {
  min-width: 120px;
  max-width: fit-content;
}

.btnsecondary-trainee {
  font-size: 16px !important;
  background-color: white !important;
  color: rgb(17, 17, 17) !important;
}

.btnsecondary-trainee:hover {
  background-color: white !important;
}

.btnprimary-trainee {
  font-size: 16px !important;
  color: #fff;
  background-color: #007f00;
}

.quesectioncontainer {
  display: flex;
}

.que__number {
  font-size: 20px;
  border-bottom: 2px solid #e8e8e8;
}

.queans__maincontainer {
  display: flex;
  min-height: 100px;
}

.reviewqueans__maincontainer {
  display: flex;
  gap: 1.5rem;
}

.que-numberindicator,
.que-description,
.review-description,
.reviewque-description,
.reviewque-numberindicator {
  font-size: 20px;
}

.que-numberindicator {
  width: 4%;
}

.que-description {
  width: 96%;
}

.reviewresponse-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 96%;
}

.desc__fontbold {
  font-weight: bold;
}

.queans-flexcontainer {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.cardPadding {
  min-height: 440px;
}

.cardPaddingInstruction {
  height: 494px;
}

.que__answerscontainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  width: 96%;
}

.reviewqa-parentcontainer {
  margin-bottom: 2rem;
}

.reviewque__answerscontainer {
  display: flex;
  flex-direction: column;
  width: 96%;
  gap: 1rem;
}

.reviewque__changeresponse-container {
  display: flex;
  justify-content: space-between;
}

.answer-container {
  display: flex;
  gap: 1.5rem;
  padding-bottom: 30px;
}

.reviewanswer-container {
  display: flex;
  gap: 4px;
}

.answer__checkbox-container {
  align-self: flex-start;
}

.answer__checkbox-container .trainee__commoncheckbox {
  margin: 0px !important;
  cursor: pointer;
}

.answer__description {
  align-self: flex-start;
}

.answer__description {
  color: #111 !important;
  cursor: pointer;
  word-break: break-word;

  font-size: 16px;
}

.que__btncontainer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.btn-style {
  width: 125px;
  font-family: 'Roboto';
  font-size: 14px;
}

.btn-style-fit {
  font-family: 'Roboto';
  font-size: 14px;
  min-width: 120px;
  max-width: 'fit-content';
}

.nav__btncontainer {
  display: flex;
  gap: 2rem;
}

.trainee-header-maincontainer {
  background-color: #007f00;
}

.header__contentcontainer {
  padding-top: 12px;
}

.header__contentcontainer h2 {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.header__contentcontainer h4 {
  color: white;
  font-size: 20px;
}

.trainee-main-parentcontainer {
  margin-inline: 39px;
  margin-block: 32px;
}

.instructions-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.instructions__heading {
  font-size: 18px;
}

.instructions__li-container {
  display: flex;
  flex-direction: column;
  gap: 10.44px;
}

.scoretable-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.score-table :is(table, td, th) {
  border: 1px solid #cecece;
  padding: 2px 2px 2px 2px;
}

.score-table table {
  border-collapse: collapse;
}

.instructions__confirmcheckbox {
  display: flex;
}

.instructions__keyitem,
.qa__timeritem {
  display: flex;
  gap: 5px;
  font-size: 16px;
}

.instruction_item {
  font-size: 16px;
}

.trainee__commoncheckbox {
  height: 25px !important;
  width: 25px !important;
  margin-top: 6px !important;
}

.confirmation__text {
  align-self: end;
  margin-left: 1rem;
  font-size: 18px;
}

.instructions-keyitemcontainer {
  display: flex;
  gap: 1rem;
  font-size: 18px;
}

.qa-headercontainer {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.qa-headercontainer {
  font-size: 20px;
}

.timer__icon {
  align-self: center;
}

.instructions__headingfont {
  font-size: 17px;
  font-family: 'Roboto-Medium';
}

.instructions__btn-container {
  display: flex;
  justify-content: flex-end;
}

.queans-footercontainer {
  display: flex;
  justify-content: space-between;
}

.footer__quenotimercontainer {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-size: 17px;
}

.card-reviewbodycontainer {
  height: 566px;
  overflow: scroll;
}

.noresponse-textcolor {
  color: red;
}

.submitmessage-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.result-screen-parentcontainer {
  margin-block: 30px;
  display: flex;
  justify-content: center;
}

.result-section-flexcontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.result-section__heading {
  font-size: 28px;
}

.card-resultbody {
  padding: 3rem 7rem;
  border: 1px solid #ebebeb;
  border-radius: 8px;
}

.result-body-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.result-body__text {
  font-size: 24px;
}

.result-body__subtext {
  font-size: 16px;
}

.result-body__score {
  font-size: 70px;
  color: #111;
}

.mytrainingdashboard-bodycontainer {
  margin-inline: 30px;
  margin-block: 20px;
}

.mt-dashboard-body__heading {
  font-size: 20px;
}

.mt-dashboard-body__subheading {
  font-size: 20px;
}

.no-assessment-message {
  display: flex;
  justify-content: center;
  height: 40vh;
  align-self: center;
  font-family: 'Roboto', sans-serif !important;
  font-size: 26px;
}

.mt-dashboard-body__section {
  display: flex;
  justify-content: space-between;
}

.training-record-container {
  flex-basis: 35%;
}

.online-assessment-container {
  flex-basis: 60%;
}

.training-record-container {
  border: 1px solid #dedede;
  height: fit-content;
}

.tr-body__heading {
  background-color: #e8e8e8;
  padding: 0.6rem;
  font-size: 17px;
  font-family: 'Roboto', sans-serif !important;
  color: black;
}

.tr-body__textcontent {
  padding: 2rem;
}

.due-textitem {
  text-align: center;
}

.due-text-color {
  color: red;
}

.due-pointer-events {
  pointer-events: none;
}

.item__due-number {
  font-size: 22px;
  font-family: 'Roboto';
  cursor: pointer;
}

.item__due-type {
  color: #111;
  font-size: 14px;
  font-family: 'Roboto';
}

.online-assessment__heading {
  gap: 2rem;
  padding-bottom: 0.7rem;
}

.online-assessment__heading {
  font-size: 18px;
  min-height: 41px;
}

.heading-text {
  font-size: 20px;
  font-family: 'Roboto', sans-serif !important;
  color: black;
}

.assessment-activeFilters-container {
  display: flex;
  gap: 0.5rem;
}

.assessment-filterText-container {
  padding: 2px;
  margin-right: 5px;
  align-items: center;
  height: 32px;
  background-color: #e8e8e8;
  border-radius: 12px;
}

.assessment-card {
  border: #ebebeb 1px solid;
}

.assessment-filterText__item {
  font-size: 12px;
  padding: 5px 5px 10px 5px;
}

.remove-filter-icon {
  cursor: pointer;
  height: 100%;
}

.assessment-item-container {
  border-top: 1px solid #dedede;
  display: flex;
  justify-content: space-between;
  padding-block: 0.5rem 0.5rem;
}

.assessment-item__textcontent {
  flex-basis: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.assessment-item-time {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
}

.txtcontent__header {
  font-family: 'Roboto', sans-serif !important;
  font-size: 18px;
}

.txtcontent__body {
  font-size: 16px;
}

.txtlink__body {
  font-size: 16px;
  cursor: pointer;
}

.txtcontent__footer {
  font-family: 'Roboto', sans-serif !important;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
}

.assessment-item__launch {
  flex-basis: 10%;
  align-self: flex-end;
  font-size: 18px;
}

#assessment-item__launch a {
  color: #007f00;
  cursor: pointer;
}

#assessment-item__launch a:hover {
  color: #007f00;
  text-decoration: underline;
}

.image-container {
  position: relative;
  overflow: hidden;
  /* Control the aspect ratio, e.g., 1:1 (100%), 16:9 (56.25%) */
}

.image-training {
  object-fit: cover;
  /* This property prevents the image from distorting */
}

@media only screen and (max-width: 1300px) {
  .mt-dashboard-body__section {
    display: block;
  }

  .que-numberindicator {
    width: 7%;
  }

  .training-record-container {
    margin-bottom: 2rem;
    width: 62%;
    max-width: 500px;
  }
}

@media only screen and (max-width: 1400px) {}

@media only screen and (max-width: 750px) {
  .mt-dashboard-body__section {
    display: block;
  }

  .training-record-container {
    margin-bottom: 2rem;
    width: 100%;
    max-width: 500px;
  }
}

@media screen and (min-height: 900px) {
  .myTrainingLandingLoader_overlay {
    height: 88vh;
  }
}

@media screen and (min-height: 700px) and (max-height: 899px) {
  .myTrainingLandingLoader_overlay {
    height: 86vh;
  }
}

.instructions__critical-icon {
  width: 26px;
  height: 24px;
  vertical-align: sub;
  margin-left: 4px;
}

@media screen and (max-width: 990px) {
  .queans__maincontainer {
    display: flex;
    min-height: 95px;
  }
}

@media only screen and (max-width: 900px) {

  /* welcome */
  .mt-dashboard-body__heading {
    font-size: 20px;
  }

  /* training status */
  .tr-body__heading {
    font-size: 18px;
  }

  /* number */
  .item__due-number {
    font-size: 20px;
  }

  /* due text  */
  .item__due-type {
    font-size: 15px;
  }

  /* online assessments  */
  .heading-text {
    font-size: 17px;
  }

  .tr-body__textcontent {
    padding: 1rem;
  }

  /* module number  */
  .txtcontent__header {
    font-size: 20px;
  }

  /* module title  */
  .txtcontent__body {
    font-size: 15px;
  }

  /* module title  */
  .txtlink__body {
    font-size: 15px;
  }

  /* launch button  */
  .runbuttontraining {
    font-size: 15px !important;
    /* margin-left: 20px; */
  }

  /* time and launch button col  */
  .assessment-item-time {
    justify-content: space-between;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 767px) {

  .que-numberindicator,
  .que-description,
  .reviewque-description {
    font-size: 16px;
  }

  .answer__description {
    font-size: 14px;
  }

  .timer__timetext,
  .qa__answeredCount {
    font-size: 14px;
  }

  .xs-hide {
    display: none;
  }

  .assessment-card {
    border-left: #007f00 4px solid;
  }

  .queans__maincontainer {
    display: flex;
    min-height: 65px;
  }
}

@media only screen and (max-width: 700px) {
  .mt-dashboard-body__heading {
    font-size: 20px;
  }

  .tr-body__heading {
    font-size: 16px;
  }

  .item__due-number {
    font-size: 18px;
  }

  .item__due-type {
    font-size: 13px;
  }

  .heading-text {
    font-size: 16px;
  }

  .tr-body__textcontent {
    padding: 1rem;
  }

  .txtcontent__header {
    font-size: 18px;
  }

  .txtcontent__body {
    font-size: 13px;
  }

  .txtlink__body {
    font-size: 13px;
  }

  .runbuttontraining {
    font-size: 13px !important;
  }

  .assessment-item-time {
    justify-content: space-between;
    font-size: 13px;
    margin-top: 5px;
  }
}

@media only screen and (max-width: 540px) {

  .que-numberindicator,
  .que-description,
  .reviewque-description {
    font-size: 16px;
  }

  .answer__description {
    font-size: 12px;
  }

  .timer__timetext,
  .qa__answeredCount {
    font-size: 14px;
  }
}

@media only screen and (max-width: 550px) {
  .online-assessment__heading {
    font-size: 18px;
  }

  .btn-style {
    width: 123px;
    font-size: 13px;
  }

  .mt-dashboard-body__heading,
  .item__due-number,
  .txtcontent__header {
    font-size: 16px;
  }

  .tr-body__heading,
  .heading-text {
    font-size: 14px;
  }

  .instruction_item {
    font-size: 14px;
  }

  .instructions__keyitem {
    font-size: 14px;
    padding-top: 5px;
  }

  .badge-text,
  .item__due-type,
  .assessment-item-time,
  .runbuttontraining,
  .txtcontent__body,
  .txtlink__body {
    font-size: 10px !important;
  }
}